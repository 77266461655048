import { useState } from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

export default function Download(props) {
    const {
        title,
        tint,
        style,
        file,
        downloadFileName,
        bc,
        tc,
        fontSize,
        paddingL,
        paddingR,
        paddingT,
        paddingB,
    } = props

    const downloadFile = () => {
        fetch(file)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement("a")
                link.href = url
                link.download =
                    downloadFileName || getFileNameFromUrl(file) || "download"
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => {
                console.error("Error downloading the file:", error)
            })
    }

    return (
        <motion.div style={{ ...style, ...containerStyle }}>
            <motion.div
                onTap={downloadFile}
                style={{
                    margin: 0,
                    // padding: "8px 16px",
                    paddingLeft: paddingL,
                    paddingRight: paddingR,
                    paddingTop: paddingT,
                    paddingBottom: paddingB,
                    borderRadius: 10,
                    border: `1px solid ${bc}`,
                    backgroundColor: tint,
                    color: tc,
                    fontSize: fontSize,
                    fontWeight: "normal",
                    width: "100%",
                    height: "100%",
                    transition: "all 0.125s ease-in-out",
                    cursor: "pointer",
                }}
            >
                <span style={{ pointerEvents: "none" }}>{title}</span>{" "}
            </motion.div>
        </motion.div>
    )
}

Download.defaultProps = {
    tint: "#09F",
    bc: "#fff",
    tc: "#fff",
    title: "Download",
    downloadFileName: "",
    fontSize: "15px",
    paddingLR: "8px",
}

addPropertyControls(Download, {
    tint: {
        title: "Tint",
        type: ControlType.Color,
    },
    bc: {
        title: "BorderColor",
        type: ControlType.Color,
    },
    tc: {
        title: "TextColor",
        type: ControlType.Color,
    },
    title: {
        title: "Label",
        type: ControlType.String,
    },
    file: {
        title: "File",
        type: ControlType.File,
        allowedFileTypes: [],
    },
    downloadFileName: {
        title: "Download File Name",
        type: ControlType.String,
        defaultValue: "",
    },
    fontSize: {
        title: "Font Size",
        type: ControlType.Number,
        defaultValue: "15px",
    },
    paddingL: {
        title: "Left Padding",
        type: ControlType.Number,
    },
    paddingR: {
        title: "Right Padding",
        type: ControlType.Number,
    },
    paddingT: {
        title: "Top Padding",
        type: ControlType.Number,
    },
    paddingB: {
        title: "Bottom Padding",
        type: ControlType.Number,
    },
})

const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}

function getFileNameFromUrl(url) {
    const decodedUrl = decodeURIComponent(url)
    const parts = decodedUrl.split("/")
    return parts[parts.length - 1]
}
